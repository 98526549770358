<template>
   <!-- <img :src = "store.getItem(personId).src" :key = "personId"/>-->
   <img :src = "'/nt/api/file/view/stbv_System_PersonsImages/filter?filter=Person_ID='+personId+'&?mheight=49'" v-if="personId" :key = "personId"/>
   <i class="bi bi-person-circle" v-else></i>
   
</template>
<script setup>
//import {ref,computed} from 'vue';
//import {srcStore} from 'system.modules.PersonImage.ts';
const props = defineProps(['personId']);

//const store = ref(srcStore);



</script>